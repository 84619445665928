export const isEmptyString = (str?: string): boolean => {
  if (!str) {
    return true;
  }

  if (!str.trim()) {
    return true;
  }

  return false;
};

const ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const isValidZip = (zip: string) => ZIP_REGEX.test(zip);
